
import Vue from "vue";
import Post from "./Post.vue";
import MentorcastPost from "@/components/Posts/MentorcastPost.vue";

export default Vue.extend({
  name: "PostContainer",
  components: {
    Post,
    MentorcastPost
  },
  props: ["post", "index"],
  watch: {
    index: function(i) {
      console.log("i", i);
    }
  },
  methods: {
    updateLike(post_id: any, counts: any) {
      this.$emit("updateLike", post_id, counts);
    },
    refreshPosts() {
      this.$emit("refreshPosts");
    }
  }
});
